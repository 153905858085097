




import PracticesCC from '/src/class-components/practices/PracticesCC.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    PracticesCC
  }
})
export default class Practices extends Vue {}
