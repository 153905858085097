var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "b-row",
            { staticClass: "px-1" },
            [
              _c(
                "b-col",
                {
                  staticClass: "my-1 px-1",
                  attrs: { md: "3", sm: "4", xs: "6" },
                },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "mb-0 d-flex align-items-center",
                      attrs: {
                        label: "Search",
                        "label-cols-sm": "3",
                        "label-align-sm": "right",
                        "label-size": "sm",
                        "label-for": "filterInput",
                      },
                    },
                    [
                      _c(
                        "b-input-group",
                        { attrs: { size: "sm" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "filterInput",
                              type: "search",
                              placeholder: "Type to Search",
                            },
                            model: {
                              value: _vm.filter,
                              callback: function ($$v) {
                                _vm.filter = $$v
                              },
                              expression: "filter",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "my-1 px-1",
                  attrs: { md: "3", sm: "4", xs: "6" },
                },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "mb-0 d-flex align-items-center",
                      attrs: {
                        label: "EHR System",
                        "label-cols-sm": "5",
                        "label-align-sm": "left",
                        "label-size": "sm",
                        "label-for": "sortBySelect",
                      },
                    },
                    [
                      _c(
                        "b-input-group",
                        { attrs: { size: "sm" } },
                        [
                          _c("b-form-select", {
                            staticClass: "w-75",
                            attrs: {
                              id: "sortBySelect",
                              options: _vm.sortOptions,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "first",
                                fn: function () {
                                  return [
                                    _c("option", { attrs: { value: "" } }, [
                                      _vm._v("All"),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.sortBy,
                              callback: function ($$v) {
                                _vm.sortBy = $$v
                              },
                              expression: "sortBy",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "my-1 px-1",
                  attrs: { md: "2", sm: "4", xs: "6" },
                },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "mb-0 d-flex align-items-center",
                      attrs: {
                        label: "Assigned User",
                        "label-cols-sm": "6",
                        "label-align-sm": "left",
                        "label-size": "sm",
                        "label-for": "sortBySelect",
                      },
                    },
                    [
                      _c(
                        "b-input-group",
                        { attrs: { size: "sm" } },
                        [
                          _c("b-form-select", {
                            staticClass: "w-75",
                            attrs: {
                              id: "sortBySelect",
                              options: _vm.sortOptions,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "first",
                                fn: function () {
                                  return [
                                    _c("option", { attrs: { value: "" } }, [
                                      _vm._v("All"),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.sortBy,
                              callback: function ($$v) {
                                _vm.sortBy = $$v
                              },
                              expression: "sortBy",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "my-1 px-1",
                  attrs: { md: "2", sm: "4", xs: "6" },
                },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "mb-0 d-flex align-items-center",
                      attrs: {
                        label: "Status",
                        "label-cols-sm": "4",
                        "label-align-sm": "left",
                        "label-size": "sm",
                        "label-for": "sortBySelect",
                      },
                    },
                    [
                      _c(
                        "b-input-group",
                        { attrs: { size: "sm" } },
                        [
                          _c("b-form-select", {
                            staticClass: "w-75",
                            attrs: {
                              id: "sortBySelect",
                              options: _vm.statusOptions,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.onStatusChanged(_vm.statusChange)
                              },
                            },
                            model: {
                              value: _vm.statusChange,
                              callback: function ($$v) {
                                _vm.statusChange = $$v
                              },
                              expression: "statusChange",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "my-1 pr-2",
                  attrs: { md: "2", sm: "4", xs: "6" },
                },
                [
                  _c(
                    "b-link",
                    {
                      staticClass: "text-body",
                      attrs: {
                        to: {
                          name: "practices-details",
                          params: { id: "new" },
                        },
                      },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            type: "button",
                            variant: "primary",
                            size: "sm",
                            block: "",
                            pill: "",
                          },
                        },
                        [_vm._v("\n            + Add Practice\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  !_vm.isLoading
                    ? _c("b-table", {
                        staticClass: "table-font-small",
                        attrs: {
                          striped: "",
                          hover: "",
                          responsive: "",
                          "per-page": _vm.perPage,
                          "current-page": _vm.currentPage,
                          items: _vm.items,
                          fields: _vm.fields,
                          "sort-by": _vm.sortBy,
                          "sort-desc": _vm.sortDesc,
                          "sort-direction": _vm.sortDirection,
                          filter: _vm.filter,
                          "filter-included-fields": _vm.filterOn,
                        },
                        on: {
                          "update:sortBy": function ($event) {
                            _vm.sortBy = $event
                          },
                          "update:sort-by": function ($event) {
                            _vm.sortBy = $event
                          },
                          "update:sortDesc": function ($event) {
                            _vm.sortDesc = $event
                          },
                          "update:sort-desc": function ($event) {
                            _vm.sortDesc = $event
                          },
                          filtered: _vm.onFiltered,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "cell(practice)",
                              fn: function (data) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(data.item.name) +
                                      "\n            "
                                  ),
                                  _c("br"),
                                  _c("span", { staticClass: "smaller-text" }, [
                                    _vm._v("ID: " + _vm._s(data.item.id)),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "cell(address)",
                              fn: function (data) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        data.item.address
                                          ? data.item.address.address1
                                          : null || "N/A"
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(contactInfo)",
                              fn: function (data) {
                                return [
                                  data.item.email
                                    ? _c("span", [
                                        _c(
                                          "span",
                                          { staticClass: "grey-text" },
                                          [_vm._v("@")]
                                        ),
                                        _vm._v(
                                          ":" +
                                            _vm._s(
                                              data.item.email.emailAddress
                                            ) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c("br"),
                                  data.item.phone
                                    ? _c(
                                        "span",
                                        [
                                          _c("feather-icon", {
                                            staticClass: "grey-text",
                                            attrs: { icon: "BriefcaseIcon" },
                                          }),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(data.item.phone.number) +
                                              "\n            "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("br"),
                                  data.item.fax
                                    ? _c(
                                        "span",
                                        [
                                          _c("feather-icon", {
                                            staticClass: "grey-text",
                                            attrs: { icon: "HomeIcon" },
                                          }),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(data.item.fax.number) +
                                              "\n            "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                            {
                              key: "cell(dateRange)",
                              fn: function (data) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        data.item.dateStart
                                          ? new Date(data.item.dateStart)
                                              .toDateString()
                                              .trim()
                                          : null || "N/A"
                                      ) +
                                      "\n            -\n            " +
                                      _vm._s(
                                        data.item.dateEnd
                                          ? new Date(data.item.dateEnd)
                                              .toDateString()
                                              .trim()
                                          : null || "N/A"
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(status)",
                              fn: function (data) {
                                return [
                                  _c(
                                    "b-badge",
                                    {
                                      attrs: {
                                        pill: "",
                                        variant: _vm.status[1][data.value],
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.status[0][data.value]) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(action)",
                              fn: function (data) {
                                return [
                                  _c(
                                    "b-link",
                                    {
                                      staticClass: "text-body",
                                      attrs: {
                                        to: {
                                          name: "practices-details",
                                          params: { id: data.item.id },
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "ripple",
                                              rawName: "v-ripple.400",
                                              value: "rgba(40, 199, 111, 0.15)",
                                              expression:
                                                "'rgba(40, 199, 111, 0.15)'",
                                              modifiers: { 400: true },
                                            },
                                          ],
                                          staticClass: "text-center",
                                          attrs: { variant: "flat-primary" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                View\n              "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3960491058
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "pb-1 pl-2", attrs: { cols: "3" } },
                [
                  _c(
                    "label",
                    { staticClass: "d-inline-block text-sm-left mr-50 pl-2" },
                    [_vm._v("Show")]
                  ),
                  _c("b-form-select", {
                    staticClass: "w-50",
                    attrs: {
                      id: "perPageSelect",
                      size: "sm",
                      options: _vm.pageOptions,
                    },
                    model: {
                      value: _vm.perPage,
                      callback: function ($$v) {
                        _vm.perPage = $$v
                      },
                      expression: "perPage",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "pb-1", attrs: { cols: "9" } },
                [
                  _c("b-pagination", {
                    staticClass: "my-0 pr-2",
                    attrs: {
                      "total-rows": _vm.totalRows,
                      "per-page": _vm.perPage,
                      align: "right",
                      size: "sm",
                    },
                    model: {
                      value: _vm.currentPage,
                      callback: function ($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-sm",
            "cancel-variant": "primary",
            "ok-only": "",
            "ok-title": "Ok",
            centered: "",
            size: "sm",
            title: "Medloop: System Alert",
            "hide-footer": _vm.result.message ? false : true,
          },
          model: {
            value: _vm.showModal,
            callback: function ($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal",
          },
        },
        [
          !_vm.result.message
            ? _c(
                "div",
                { staticClass: "text-center w-100 mt-2 mb-2" },
                [
                  _c("b-spinner", {
                    attrs: { variant: "primary", label: "Loading..." },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("b-card-text", { attrs: { else: "" } }, [
            _vm._v(_vm._s(_vm.result.message)),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }